<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <v-btn text color="primary" @click="back">
          <v-icon left>fa-solid fa-left-long</v-icon>
          Voltar
        </v-btn>
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Consultar e filtrar contratos">
      <template v-slot:form>
        <v-form @submit.prevent="search" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseDatePicker
                label="Início de vigência"
                hide-details
                v-model="filter.startEffectiveDate"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseDatePicker
                label="Final de vigência"
                hide-details
                v-model="filter.endEffectiveDate"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseProductType
                label="Produto"
                hide-details
                clearable
                v-model="filter.productType"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseInsuranceCompany
                label="Seguradora / Operadora"
                hide-details
                clearable
                v-model="filter.insuranceCompanyId"
              />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <BaseCustomersAutocomplete
                label="Cliente"
                hide-details
                clearable
                v-model="filter.customerId"
              />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <BaseButton
                type="submit"
                height="40"
                color="primary"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
              />
              <BaseButton
                height="40"
                color="primary"
                title="Limpar"
                outlined
                @click="clean"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <v-row v-if="items.length > 0">
      <v-col
        v-for="(card, index) in cards"
        :key="index"
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        <CardEffect
          :icon="card.icon"
          :description="card.title"
          :value="card.value"
        />
      </v-col>
    </v-row>

    <BaseTableList title="Listagem de contratos" right v-if="items.length > 0">
      <template v-slot:right>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              text
              color="success"
              v-bind="attrs"
              v-on="on"
              @click="getXls"
            >
              <v-icon color="success">fa-regular fa-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar excel</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              text
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="getPdf"
            >
              <v-icon color="error">fa-regular fa-file-pdf</v-icon>
            </v-btn>
          </template>
          <span>Gerar pdf</span>
        </v-tooltip>
      </template>

      <template v-slot:table>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :sort-desc="true"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.customerName`]="{ item }">
            <span :title="item.customerName">
              {{ doTruncateText(item.customerName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.subestipulantName`]="{ item }">
            <span :title="item.subestipulantName">
              {{ doTruncateText(item.subestipulantName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.insuranceCompanyName`]="{ item }">
            <span :title="item.insuranceCompanyName">
              {{ doTruncateText(item.insuranceCompanyName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.expirationDate`]="{ item }">
            {{ formatDate(item.expirationDate) }}
          </template>
        </v-data-table>
      </template>
    </BaseTableList>
  </v-container>
</template>

<script>
import { downloadFile } from '@/helpers/download';
import { generatePdf } from '@/helpers/report';
import { doTruncateText } from '@/helpers/utils';
import { formatDate } from '@/helpers/formatting';
import { ReportService } from '@/services/api/report';

export default {
  components: {
    CardEffect: () => import('@/components/cards/card-effect')
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    cards: [],
    options: {},
    filter: {
      startEffectiveDate: null,
      endEffectiveDate: null,
      insuranceCompanyId: null,
      customerId: null,
      productType: null
    },
    headers: [
      { text: 'Vidas', value: 'amountLives', width: 100 },
      { text: 'Estipulante', value: 'customerName', width: 250 },
      { text: 'Subestipulante', value: 'subestipulantName', width: 250 },
      { text: 'Operadora', value: 'insuranceCompanyName', width: 250 },
      { text: 'Produto', value: 'productType', width: 200 },
      { text: 'Contrato', value: 'contractCode', width: 150 },
      { text: 'Proposta', value: 'proposalCode', width: 150 },
      { text: 'Venc. do contrato', value: 'expirationDate', width: 180 },
      { text: 'Dia de corte', value: 'cutOffDay', width: 140 },
      { text: 'Venc. da fatura', value: 'expiryDay', width: 170 }
    ]
  }),

  methods: {
    doTruncateText,
    formatDate,

    back() {
      this.$router.push({ name: 'relatorios' });
    },

    clean() {
      this.$refs.form.reset();

      this.items = [];
      this.totalRecords = 0;
      this.cards = [];
    },

    async search() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const params = {
        page: page || 1,
        take: itemsPerPage || 10,
        orderProperty: sortBy ? sortBy[0] : null,
        orderDesc: sortDesc ? sortDesc[0] : null,
        ...this.filter
      };

      this.get(params);
      this.getTotalizer(this.filter);
    },

    async get(params) {
      try {
        const reportService = new ReportService();
        const { status, data } = await reportService.getContracts(params);

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getTotalizer(params) {
      try {
        const reportService = new ReportService();
        const { status, data } = await reportService.getContractTotalizer(
          params
        );

        if (status === 200) {
          this.cards = [
            {
              icon: 'fa-solid fa-file-contract',
              title: 'Contratos',
              value: data.amountContracts
            },
            {
              icon: 'fa-solid fa-tooth',
              title: 'Vidas em assistência odontológica',
              value: data.amountDentalLives
            },
            {
              icon: 'fa-solid fa-stethoscope',
              title: 'Vidas em assistência médica',
              value: data.amountHealthLives
            },
            {
              icon: 'fa-solid fa-heart-pulse',
              title: 'Vidas em seguro de vida',
              value: data.amountLifeInsuranceLives
            }
          ];
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getXls() {
      try {
        const reportService = new ReportService();
        const { status, data } =
          await reportService.getDownloadXlsContractReport(this.filter);

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getPdf() {
      try {
        const reportService = new ReportService();
        const { status, data } =
          await reportService.getExportDataContractReport(this.filter);

        if (status === 200) {
          const columns = [
            { header: 'Vidas', dataKey: 'amountLives' },
            { header: 'Estipulante', dataKey: 'customerName' },
            { header: 'Subestipulante', dataKey: 'subestipulantName' },
            { header: 'Operadora', dataKey: 'insuranceCompanyName' },
            { header: 'Produto', dataKey: 'productType' },
            { header: 'Contrato', dataKey: 'contractCode' },
            { header: 'Proposta', dataKey: 'proposalCode' },
            { header: 'Venc. do contrato', dataKey: 'expirationDate' },
            { header: 'Dia de corte', dataKey: 'cutOffDay' },
            { header: 'Venc. da fatura', dataKey: 'expiryDay' }
          ];

          const styles = {
            fontSize: 10,
            fontStyle: 'bold',
            fillColor: [239, 230, 241]
          };

          const items = [
            ...data,
            [
              {
                content: `${this.cards[0].title}: ${this.cards[0].value}`,
                colSpan: 2,
                styles
              },
              {
                content: `${this.cards[1].title}: ${this.cards[1].value}`,
                colSpan: 2,
                styles
              },
              {
                content: `${this.cards[2].title}: ${this.cards[2].value}`,
                colSpan: 3,
                styles
              },
              {
                content: `${this.cards[3].title}: ${this.cards[3].value}`,
                colSpan: 3,
                styles
              }
            ]
          ];

          generatePdf(columns, items, 'Relatório de contratos');
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
